.container {
  padding-right: 0;
  padding-left: 0;
}

.container,
.container-fluid {
  --bs-gutter-x: 0;
  position: relative;
}

.row {
  --bs-gutter-x: 0;
}

// sticky-header
.sticky-header {
  position: relative;
  z-index: 1;
  background: $body-bg;
  justify-content: center;
  width: 100%;
  display: flex;
  align-items: center;
  // height: 6.5rem;
  -moz-box-shadow: 0 5px 5px rgba(182, 182, 182, 0.75);
  -webkit-box-shadow: 0 5px 5px rgba(182, 182, 182, 0.75);
  box-shadow: 0 5px 5px rgba(182, 182, 182, 0.75);

  .navbar {
    --bs-navbar-brand-padding-y: 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    @include media-breakpoint-down(sm) {
      padding-bottom: 0;
    }
  }

  .navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
  }

  .logo {
    display: flex;
    justify-items: center;
    align-items: center;
    padding-left: 0.57rem;
  }

  .logo h1 {
    font-family: $font-family-open;
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2.0625rem;
    letter-spacing: 0.03125rem;
    text-transform: uppercase;
    margin: 0;

    @include media-breakpoint-down(md) {
      font-size: 1rem;
      line-height: 1.361875rem;
    }
  }

  .logo img {
    width: 2.3rem;
    height: auto;
    display: block;
    @include media-breakpoint-down(md) {
      width: 1.75rem;
    }
  }

  .logo .separator {
    height: 1.5rem;
    width: 0.0625rem;
    border: 0.0625rem solid $vanadyl-blue;
    margin: 0 0.625rem;

    @include media-breakpoint-down(md) {
      height: 1.2rem;
    }
  }
}

.sticky-map {
  width: 100%;
  height: calc(100vh - 7.2rem);
  position: relative;
  z-index: 0;

  .strategy {
    margin-top: 0;

    @include media-breakpoint-down(lg) {
      position: absolute;
      z-index: 99;
      background-color: $body-bg;
      height: calc(100vh - 7.4rem);
      top: auto;
      margin-top: 0;
      font-size: 0.6875rem;
      width: 80%;
      transform: translate(-50%, 0);
      left: 50%;
      top: 3.5rem;
    }

    @include media-breakpoint-down(md) {
      width: 90%;
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  .map {
    @include media-breakpoint-down(sm) {
      z-index: 0;
    }
  }

  .strategy-mobile {
    @include media-breakpoint-down(md) {
      position: absolute;
      z-index: 99;
      background-color: $body-bg;
      height: 14rem;
      margin-top: 0;
      font-size: 0.6875rem;
      width: 95%;
      left: 50%;
      bottom: 6.3rem;
      transform: translateX(-50%);
      border-radius: 0.625rem;
      overflow: hidden;

      &.selected {
        height: 17rem;
      }

      .item-strategy {
        border: none;
      }

      .strategy-row {
        position: relative;
        height: 100%;
      }

      .strategy-close {
        position: absolute;
        top: 5px;
        right: 0;
        width: 100%;
        float: right;
        justify-content: end;
        display: flex;
        text-decoration: none;
      }
    }
  }

  .leaflet-container {
    width: 100%;
    height: calc(100vh - 6.5rem);
    max-width: 100%;
    @include media-breakpoint-down(sm) {
      height: 100vh;
    }
  }

  .quote .leaflet-container {
    height: calc(100vh - 3.2rem);
    @include media-breakpoint-down(lg) {
      height: 100vh;
    }
    @include media-breakpoint-down(sm) {
      height: 100vh;
    }
  }

  h6 span.badge {
    color: $body-bg;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 3px;
    background-color: $link-color !important;
  }
}

.quote {
  .back-icon {
    font-size: 32px;
    cursor: pointer;
  }

  .quote_details {
    @include media-breakpoint-down(lg) {
      width: 95%;
      position: absolute;
      top: 60%;
      left: 50%;
      z-index: 999;
      transform: translate(-50%, -50%);
      background: $body-bg;
      max-width: 767px;
    }
    @include media-breakpoint-down(md) {
      width: 100%;
      top: 23rem;
    }
  }

  .quote_details_strategy {
    @include media-breakpoint-down(lg) {
      width: 95%;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 999;
      transform: translate(-50%, -50%);
      background: $body-bg;
      max-width: 767px;
    }
    @include media-breakpoint-down(sm) {
      top: 60%;
      width: 100%;
    }
  }

  .estimated-cost {
    @include media-breakpoint-down(sm) {
      width: 100%;
      padding: 1rem 0.5rem;
    }

    .estimaded {
      margin-top: -4%;
      @include media-breakpoint-down(sm) {
        margin-top: 1%;
      }
    }

    .margin {
      strong {
        @include media-breakpoint-down(sm) {
          margin-left: -0.8rem;
        }
      }
    }
  }

  .list-group-item {
    @include media-breakpoint-down(sm) {
      font-size: 0.8rem;
    }
  }

  .text-right {
    text-align: right;
  }

  .details {
    background-color: $body-bg;
    padding: 1rem 1rem 0 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: self-start;

    @include media-breakpoint-down(lg) {
      padding: 1rem;
    }

    @include media-breakpoint-down(md) {
      padding: 0 1rem;
    }

    .details_strategy {
      width: 100%;

      .details_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
      }

      .details_content {
        p {
          margin-bottom: 0.4rem;
          color: $body-color;
          font-family: $font-family-inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          white-space: break-spaces;
          width: 90%;

          strong {
            font-size: 12px;
            font-weight: 700;
            @include media-breakpoint-down(md) {
              font-size: 12px;
            }
          }
        }

        .border-right {
          position: relative;

          &::after {
            content: ' ';
            background-color: $body-color;
            height: 4rem;
            width: 2px;
            display: inline-block;
            position: absolute;
            top: 40%;
            right: 5%;
            transform: translateY(-50%);

            @include media-breakpoint-down(sm) {
              background: transparent;
            }
          }
        }
      }
    }
  }
}

h3 {
  color: $body-color;
  font-family: $font-family-nunito;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include media-breakpoint-down(xxl) {
    font-size: 20px;
  }
  @include media-breakpoint-down(md) {
    font-size: 18px;
  }
}

.details_nav {
  background: #f5f5f5;
  padding: 1.5rem 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: first baseline;
  height: calc(100vh - 4rem);

  @include media-breakpoint-down(md) {
    background: #fff;
    align-items: center;
    flex-direction: row;
    height: 3rem;
    width: 100vw;
    padding-top: 2rem;
    border-bottom: 1px solid rgba(148, 148, 148, 1);
    padding-left: 0.7rem !important;
  }

  .nav_icon {
    padding-bottom: 0.8rem;
    border-bottom: 1px solid var(--rak-rgba-blackMana);
    display: block;
    margin-bottom: 0.8rem;

    @include media-breakpoint-down(md) {
      padding-bottom: 0;
    }
  }

  .nav-pills {
    @include media-breakpoint-down(md) {
      margin-left: 1rem;
    }
  }

  .nav-pills .nav-link {
    background: $body-bg;
    border: 1px solid $link-color;
    border-radius: 50%;
    padding: 0;
    display: flex;
    width: 30px;
    height: 30px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: $link-color;
    text-align: center;
    font-family: $font-family-inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 0.8rem;
    cursor: pointer;

    @include media-breakpoint-down(md) {
      margin-right: 0.6rem;
    }

    &.active {
      background: $link-color;
      color: $body-bg;

      &.discarded {
        background: $discarded-color;
        border-color: $discarded-color;
        color: $body-bg;
      }
    }

    &.discarded {
      border-color: $discarded-color;
      color: $discarded-color;
    }
  }
}

.tab-content {
  > .tab-pane {
    padding: 1.5rem 2rem;
    height: 93vh;
    overflow-y: auto;

    @include media-breakpoint-down(md) {
      padding: 1rem;
      height: 100vh;
    }

    .tab-header {
      width: 100%;

      h4 {
        color: #009cdf;
        font-family: $font-family-inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
      }
    }

    .border-bottom {
      border-bottom: 1px solid var(--rak-rgba-blackMana) !important;
    }
  }
}

.modal-content {
  @include media-breakpoint-down(md) {
    padding: 1rem !important;
  }
}

.list-group {
  --bs-list-group-border-width: 0;
  --bs-list-group-border-radius: 0;
  --bs-list-group-item-padding-x: 0.5rem;
  --bs-list-group-item-padding-y: 0.5rem;

  .list-group-item {
    padding: 0.4rem;
  }
}

.custom-icon-marker,
.svg-icon-marker {
  background: $body-bg;
  width: 34px;
  border-radius: 50%;
  height: 34px;
  text-align: center;
  color: rgba(0, 127, 182, 1);
  border: 0.15rem solid rgba(0, 127, 182, 1);
  z-index: 9999;
  position: relative;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $font-family-open;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;

  &.discarded {
    border-color: rgb(255, 153, 0);
    color: rgb(255, 153, 0);
  }
}

.leaflet-bottom {
  @include media-breakpoint-down(md) {
    bottom: 3rem !important;
  }
}

.icon-obstacle {
  color: #ff9900;
  top: 1px;
  font-size: 1.2rem;
  position: relative;
}

.search-container {
  .power {
    .form-control-rak {
      @include media-breakpoint-down(sm) {
        width: 100% !important;
      }
    }
    .power-icon {
      @include media-breakpoint-down(sm) {
        right: 15% !important;
      }
    }
  }
  .form-select {
    background-color: rgb(245, 245, 245);
    font-size: 0.7rem;
    z-index: 0 !important;
    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }

  .form-control-rak {
    height: 2.07rem;
  }
}

.collapse-search {
  position: relative;
  .clear-search {
    position: absolute;
    bottom: -3.2rem;
    left: -0.5rem;
    border: none;
    padding: 0.4rem 0.6rem;
    font-size: 1.3rem;
    vertical-align: middle;
    border-bottom-right-radius: 0.8rem 0.8rem;
    box-shadow: 0 5px 5px rgba(182, 182, 182, 0.75);
  }
}
