

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-search_black_24dp-1:before {
  content: "\e912";
}
.icon-Frame-134:before {
  content: "\e90d";
}
.icon-download:before {
  content: "\e90e";
}
.icon-checkbox:before {
  content: "\e90f";
}
.icon-list_bulleted1:before {
  content: "\e910";
  color: #2f80ed;
}
.icon-logo:before {
  content: "\e911";
}
.icon-subdirectory_arrow_right:before {
  content: "\e900";
}
.icon-alt_route:before {
  content: "\e901";
}
.icon-close:before {
  content: "\e902";
}
.icon-equiv-arrow:before {
  content: "\e903";
}
.icon-list_bulleted:before {
  content: "\e904";
}
.icon-uniE905:before {
  content: "\e905";
}
.icon-uniE906:before {
  content: "\e906";
}
.icon-floppy2:before {
  content: "\e907";
}
.icon-uniE908:before {
  content: "\e908";
}
.icon-uniE909:before {
  content: "\e909";
}
.icon-Pensil:before {
  content: "\e90a";
}
.icon-rectangle:before {
  content: "\e90b";
}
.icon-separator-point:before {
  content: "\e90c";
}

.icon-sort:before {
  content: "\e913";
}

.icon-arrow_down:before {
  content: "\e914";
}
.icon-arrow_up:before {
  content: "\e915";
}
.icon-description:before {
  content: "\e916";
}
.icon-map:before {
  content: "\e917";
}
.icon-refresh:before {
  content: "\e918";
}

.icon-chat_bubble:before {
  content: "\e919";
}
.icon-delete:before {
  content: "\e91a";
  color: #fff;
}
.icon-Frame-476:before {
  content: "\e91b";
  color: #009cdf;
}
.icon-person:before {
  content: "\e91c";
}

.icon-Vector:before {
  content: "\e91e";
}
.icon-mat-btn .path1:before {
  content: "\e91f";
  color: rgb(255, 255, 255);
}
.icon-mat-btn .path2:before {
  content: "\e920";
  margin-left: -1em;
  color: none;
}
.icon-mat-btn .path3:before {
  content: "\e921";
  margin-left: -1em;
  color: rgb(0, 156, 223);
}
.icon-alt_route1:before {
  content: "\e922";
  color: #009cdf;
}