@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-Regular.eot');
    src: url('OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('OpenSans-Regular.woff2') format('woff2'),
        url('OpenSans-Regular.woff') format('woff'),
        url('OpenSans-Regular.ttf') format('truetype'),
        url('OpenSans-Regular.svg#OpenSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-Bold.eot');
    src: url('OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('OpenSans-Bold.woff2') format('woff2'),
        url('OpenSans-Bold.woff') format('woff'),
        url('OpenSans-Bold.ttf') format('truetype'),
        url('OpenSans-Bold.svg#OpenSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('Inter-Regular.eot');
    src: url('Inter-Regular.eot?#iefix') format('embedded-opentype'),
        url('Inter-Regular.woff2') format('woff2'),
        url('Inter-Regular.woff') format('woff'),
        url('Inter-Regular.ttf') format('truetype'),
        url('Inter-Regular.svg#Inter-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('Inter-Bold.eot');
    src: url('Inter-Bold.eot?#iefix') format('embedded-opentype'),
        url('Inter-Bold.woff2') format('woff2'),
        url('Inter-Bold.woff') format('woff'),
        url('Inter-Bold.ttf') format('truetype'),
        url('Inter-Bold.svg#Inter-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito Sans';
    src: url('NunitoSans-Regular.eot');
    src: url('NunitoSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('NunitoSans-Regular.woff2') format('woff2'),
        url('NunitoSans-Regular.woff') format('woff'),
        url('NunitoSans-Regular.ttf') format('truetype'),
        url('NunitoSans-Regular.svg#NunitoSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'icomoon';
    src:  url('icomoon.eot?k6jjcd');
    src:  url('icomoon.eot?k6jjcd#iefix') format('embedded-opentype'),
      url('icomoon.ttf?k6jjcd') format('truetype'),
      url('icomoon.woff?k6jjcd') format('woff'),
      url('icomoon.svg?k6jjcd#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }