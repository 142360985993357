@use './variables';

.navbar-nav {

  @include media-breakpoint-down(lg) {
    flex-direction: row;
  }

  &.me-auto {  
    @include media-breakpoint-down(sm) {
      margin-right: 0 !important;
    }
  }

  @include media-breakpoint-down(sm) {
    flex-direction: row;
  }

  a {
    color: $body-color;
    font-family: $font-family-nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    padding-left: 4rem;
    display: flex;
    align-items: center;
    text-decoration: none;

    .icon {
      margin-right: 0.4rem;
    }

    &.active,
    &:hover,
    &:focus {
      color: $link-color;

      .icon, svg {
        fill: $link-color;
      }
    }

    &:last-child {
      @include media-breakpoint-down(sm) {
        margin-right: 1rem;
      }
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      padding-left: 1rem;
    }

    span {
      @include media-breakpoint-down(sm) {
        font-size: 11px;
      }
    }
  }
}

.navbar-collapse {
  background: rgb(255, 255, 255);
  padding: 0.4rem 0;
  @include media-breakpoint-down(md) {
    box-shadow: 0 5px 5px rgba(182, 182, 182, 0.75);
  }
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none;
}
