@use '../fonts/fonts.scss';
//
// Variables
// --------------------------------------------------
// Default variable overrides
$body-bg: #fff;
$body-color: #000;
$link-color: rgba(0, 156, 223, 1);
$discarded-color: rgb(255, 153, 0);
// colors
$blue-fire-rak: rgba(0, 156, 223, 1);
$white-rak: #ffffff;
$christmas-silver-rak: #e0e0e0;
$black-rak: #000000;
$black-wash: #0d0d0d;
$cerebral-grey-rak: #cccccc;
$lead-rak: #212121;
$winters-day: #dff5ff;
$vanadyl-blue: #09a0e1;
$link-color-hover: rgb(0, 156, 223);
// fonts
$font-family-open: 'Open Sans', sans-serif !default;
$font-family-inter: 'Inter', sans-serif !default;
$font-family-nunito: 'Nunito Sans', sans-serif !default;
// Default variable overrides
$body-bg: #fff;
$body-color: $black-rak;

// buttons
$btn-border-width: 0;
$bg-search: rgba(242, 242, 242, 1);
$bg-search-hover: rgba(242, 242, 242, 0.9);
$btn-carte: rgba(47, 128, 237, 1);
$color-search: #11161a;

$font-size-base: 13px !default;

// FORM checkbox
$form-switch-width: 3rem;
$form-check-input-bg-color: var(--black-038, rgba(0, 0, 0, 0.38));
$form-checked-color: rgba(255, 153, 0, 1);
