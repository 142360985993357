.search-text {
  @include format-text($font-family-open, 0.8125rem, 16px, $lead-rak);
}
.show {
  display: block;
}
.hide {
  display: none;
}
.row {
  @include media-breakpoint-down(sm) {
    --bs-gutter-x: 0;
  }
}
.indicator-text {
  @include format-text($font-family-inter, 0.8125rem, 0.6875rem, $black-rak);

  @include media-breakpoint-down(lg) {
    @include format-text($font-family-inter, 0.7rem, 0.6875rem, $black-rak);
  }
  @include media-breakpoint-down(sm) {
    @include format-text($font-family-inter, 0.6rem, 0.6rem, $black-rak);
  }
}

.fst-italic {
  @include media-breakpoint-down(sm) {
    @include format-text($font-family-inter, 0.62rem, 0.6rem, $black-rak);
  }
}

.required {
  color: red;
}

.item-strategy {
  margin: 0 0.5rem 0 0;
  padding-left: 0.625rem;
  padding-right: 0;
  border-bottom: 2px solid var(--rak-rgba-blackMana);

  &:last-child {
    border-bottom: none;
  }

  &:hover,
  &.active {
    background-color: var(--rak-wintersDay);
    opacity: 1;
  }

  @include media-breakpoint-down(sm) {
    margin: 0 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.strategy-row {
  .flex-item-1,
  .flex-item-2 {
    @include media-breakpoint-down(md) {
    }
  }
}

.collapse:not(.show) {
  display: contents;

  @include media-breakpoint-down(lg) {
    display: none;
  }
}
.blue-text-rak {
  color: var(--rak-palette-atmosphere);
  font-weight: 800;
}
.svg-icon-marker {
  cursor: pointer;
}

.leaflet-control-attribution.leaflet-control {
  display: none;
}

.leaflet-popup-content-wrapper {
  border-radius: 0.1875rem !important;
  .leaflet-popup-content {
    margin: 0.3125rem 0.625rem !important;
  }
}

.alert {
  padding: 1rem;
}

.title-primary {
  color: rgba(0, 127, 182, 1);
}

.icon-location_on {
  font-size: 1.3rem;
  color: #ff9900;
}

.shadow-box {
  filter: drop-shadow(-1px -1px 0px #186bd4);
}

.leaflet-popup,
.leaflet-popup-content {
  min-width: 180px !important;
}

.Toastify__toast-container {
  z-index: 2000;
}