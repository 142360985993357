.progress-container {
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  display: flex;
  height: auto;
  padding-top: 0.8rem;
  flex-direction: column;
  justify-items: baseline;
  width: 99%;

  .title {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0em;
    text-align: left;
  }

  .progress-bar {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .progress-value {
      height: 20px;
      position: relative;
      overflow: inherit;
      margin-right: 1px;
      &.last {
        .progress-value,
        .progress-info {
          right: 0;
        }
      }
    }
  }

  .progress-info {
    color: rgb(0, 0, 0);
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1;
    text-align: left;
    white-space: break-spaces;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    > span {
      position: relative;
    }

    .infos {
      display: flex;
      flex-direction: row;
      justify-items: center;
      justify-content: space-around;

      .box {
        width: 1rem;
        height: 1rem;
        margin-right: 0.5em;
        display: inline-block;
        &.hot {
          background-color: rgba(244, 127, 127, 1);
        }
        &.cold {
          background-color: rgba(127, 201, 255, 1);
        }
        &.inter {
          background-color: rgba(156, 200, 155, 1);
        }
        &.very-urban {
          background-color: rgba(59, 0, 92, 1);
        }
        &.urban {
          background-color: rgba(94, 46, 116, 1);
        }
        &.semi-urban {
          background-color: rgba(148, 121, 160, 1);
        }
        &.rural {
          background-color: rgba(199, 193, 202, 1);
        }
      }

      .box-infos {
        display: flex;
        flex-direction: column;
        padding-top: 0.2rem;

        .box-label {
          padding-top: 0.2rem;
        }
      }
    }
  }
}
