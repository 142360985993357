@use './variables';

.btn {
  border-radius: 0;
  --bs-btn-padding-y: 0.37rem;
}

.btn-rak-primary {
  background-color: $blue-fire-rak;
  color: $white-rak;
  border-radius: 1px;
  font-family: $font-family-open;
  font-size: 0.875rem;
  line-height: 1rem;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  border: 0;
  padding: 0.625rem 0.5rem;

  &:hover,
  &:active,
  &:visited,
  &:focus {
    background-color: $link-color-hover;
    color: $white-rak !important;
  }

  &:disabled {
    background-color: $christmas-silver-rak !important;
    color: $white-rak !important;
  }

  .icon {
    font-size: 1rem;
  }
}
.btn-rak-secondary {
  background-color: $christmas-silver-rak;
  color: $black-rak;
  height: 32px;
  border-radius: 16px;
  font-family: $font-family-inter;
  font-size: 0.8125rem;
  line-height: 1.25rem;
  font-weight: 400;
  border: 0;
  &.active {
    background-color: $winters-day;
    color: $vanadyl-blue;
  }
}

.btn-rak-link {
  background-color: transparent;
  color: $black-rak;
  font-family: $font-family-inter;
  font-size: 0.8125rem;
  line-height: 1.25rem;
  font-weight: 400;
  border: 0;
  &.active, :hover {
    background-color: transparent;
    color: $black-rak;
  }
}

.btn-outline-primary {
  border: 1px solid rgb(0, 156, 223);
  color: rgb(0, 156, 223);
  border-radius: 4px;
  font-family: $font-family-open;
  font-size: 0.875rem;
  line-height: 1rem;
  font-weight: 400;
  letter-spacing: 1px;
  padding: 0.625rem 0.5rem;

  &:hover,
  &:active,
  &:visited,
  &:focus {
    background-color: rgb(0, 156, 223);
    color: $white-rak !important;
  }

  &:disabled {
    background-color: $christmas-silver-rak !important;
    color: $white-rak !important;
  }

  .icon {
    font-size: 1rem;
  }
}



.btn-display-search {
  position: absolute;
  background: $bg-search;
  border: none;
  top: 0.5rem;
  z-index: 999;
  width: 7rem;
  height: 2.5rem;
  padding: 0.25rem 0.75rem;
  left: 0.5rem;
  border-radius: 0.5rem;
  font-family: $font-family-open;
  font-style: normal;
  font-weight: 400;
  font-size: 0.8125rem;
  line-height: 1.5rem;
  color: $color-search;
  display: flex;
  align-items: center;
  justify-content: center;

  // @include media-breakpoint-only(md) {
  //   top: 0.5rem;
  // }

  :hover,
  .active,
  :active {
    background: $bg-search-hover;
    color: $body-bg;
    border: none;

    .search-icon {
      color: $body-bg !important;
    }
  }

  > .search-icon {
    color: $body-color;
    font-size: 1.1rem;
    font-weight: 700;
    margin-left: 0.3rem;
    position: relative;
    top: 0.1rem;
  }
}

.btn-display-strategy {
  position: absolute;
  background: $bg-search;
  border: none;
  bottom: 1rem;
  z-index: 9999;
  width: 9rem;
  height: 2.5rem;
  padding: 0.25rem 0.75rem;
  right: 0.5rem;
  border-radius: 0.5rem;
  font-family: $font-family-open;
  font-style: normal;
  font-weight: 400;
  font-size: 0.8125rem;
  line-height: 1.5rem;
  color: $btn-carte;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  @include media-breakpoint-down(md) {
    top: 0.5rem;
    bottom: auto;
    max-width: 7.5rem;
    width: 100%;
    color: #000;
  }

  &.selected-mobile {
    bottom: 14.2rem !important;

    &.selected-strategy {
      bottom: 17.5rem !important;
    }
  }
  position: absolute;
  background: $bg-search;
  border: none;
  bottom: 1rem;
  z-index: 9999;
  width: 9rem;
  height: 2.5rem;
  padding: 0.25rem 0.75rem;
  right: 0.5rem;
  border-radius: 0.5rem;
  font-family: $font-family-open;
  font-style: normal;
  font-weight: 400;
  font-size: 0.8125rem;
  line-height: 1.5rem;
  color: $btn-carte;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  @include media-breakpoint-down(sm) {
    top: 0.5rem;
    bottom: auto;
    max-width: 7rem;
    width: 100%;
    color: #000;
  }

  &.selected-mobile {
    bottom: 14.2rem !important;

    &.selected-strategy {
      bottom: 17.5rem !important;
    }
  }

  &.btn-list {
    bottom: 4rem;

    @include media-breakpoint-down(sm) {
      top: 0.5rem;
      bottom: auto;
    }
  }

  @include media-breakpoint-only(md) {
    right: 1rem;
  }

  :hover,
  .active,
  :active {
    background: $bg-search-hover;
    color: $btn-carte;
    border: none;
  }

  > .search-icon {
    fill: $btn-carte;
    font-size: 1.3rem;
    font-weight: 700;
    margin-right: 0.3rem;
  }
}

.btn-display-map {
  background: transparent;
  border: 2px solid $link-color;
  width: 9rem;
  height: 2.5rem;
  border-radius: 4px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 0.82rem;
  line-height: 1.5rem;
  color: $link-color !important;
  outline: none;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  align-items: center;

  :hover,
  .active,
  :active {
    background: $bg-search-hover;
    color: $btn-carte;
    border: none;
  }

  > .search-icon {
    fill: $btn-carte;
    font-size: 1rem;
    font-weight: 700;
    margin-right: 0.4rem !important;
  }
}

.dropdown-item.active,
.dropdown-item:active {
  color: $white-rak;
  text-decoration: none;
  background-color: $blue-fire-rak;
}

.dropdown-item {
  font-size: 0.875rem;
}

.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  color: $white-rak;
  background-color: $blue-fire-rak;
  border-color: $blue-fire-rak;

  > .icon {
    color: $white-rak;
  }
}

.search-container.btn-check:checked + .btn,
.search-container :not(.btn-check) + .btn:active,
.search-container.btn:first-child:active,
.search-container .btn.active,
.search-container.btn.show,
.search-container .btn-link.active .search-container .btn-link:active {
  color: $blue-fire-rak;
  background-color: transparent !important;
  border-color: transparent !important;
}
