@import '~leaflet/dist/leaflet.css';
@import '~bootstrap/scss/bootstrap';
@import './sass/variables';
@import './sass/icomoon';
@import '~react-toastify/dist/ReactToastify.css';
@import '~react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

@import './sass/utilities';
@import './sass/mixins';
@import './sass/root';
@import './sass/buttons';
@import './sass/navbar';
@import './sass/progressBar';
@import './sass/forms';
@import './sass/containers';
@import './sass/grid';
@import './sass/checkbox';
@import './sass/common';

body {
  margin: 0;
  font-family: $font-family-inter;
  color: $body-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 400;
  min-width: 320px;
}


