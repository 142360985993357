.form-control-rak {
  background: $white-rak;
  border: 0.0625rem solid $cerebral-grey-rak;
  border-radius: 0.1875rem;
  height: 2rem;
  padding: 0.375rem;
  width: 100%;

  &[placeholder] {   
    padding-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 10px;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 10px;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 10px;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 10px;
  }

  &::-webkit-input-placeholder {
    font-family: $font-family-inter;
    font-style: normal;
    font-weight: 300;
    font-size: 0.8125rem;
    color: $lead-rak;
  }
  &:disabled {
    cursor: default;
    background: rgba(239, 239, 239, 0.8) !important;
    color: rgb(84, 84, 84, 0.8) !important;
    border-color: rgba(118, 118, 118, 0.3) !important;
  }

  &.is-invalid {
    border: 0.0625rem solid #dc3545;
  }

  &:focus,
  &:focus-visible {
    border: 0.0625rem solid rgba(0, 127, 182, 1);
    outline-color: rgba(0, 127, 182, 1);
  }
}
.form-check-input[type='checkbox'] {
  width: 0.875rem;
  height: 0.875rem;
  background-color: $white-rak;
  border: 2px solid $black-rak;
  font-size: 1rem;
  border-radius: 0;
  cursor: pointer;
}

.form-check-input:checked[type='checkbox'] {
  background-image: url('../../images/checkbox.svg');
}
.form-select {
  font-family: $font-family-inter;
  font-style: normal;
  font-weight: 400;
  font-size: 0.8125rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
